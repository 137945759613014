/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React from "react";

import Dashboard from "./Dashboard";

function Home() {
  return (
    <>
      <div id="layout-wrapper">
        <Dashboard />
      </div>
    </>
  );
}

export default Home;
